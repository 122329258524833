import {BackendJkUserService, FrontendJkUserService} from '@common/api';
import {Alert, Popconfirm, Typography} from 'antd';
import {CustomButton} from 'components/Common/Button';
import {CommonManager} from 'components/Common/Manager';
import {CommonTable} from 'components/Common/Table';
import {useApi} from 'hooks/useApi';
import {useConfig} from 'hooks/useConfig';
import {genRequest} from 'libs/genRequest';
import {minute2time} from 'libs/time';
import React, {useMemo} from 'react';
import withProps from 'recompose/withProps';
import {VerifyEmail} from '../VerifyEmail';
import {JKUserBatchUpdate} from './BatchUpdate';

import {useAuthorize} from 'core/Authorize';
import {JKUserEdit} from './Edit';
import {BackendJKUserNewOffDate} from './NewOffDate';

export type JKUserManagerProps = {
  userId?: string;
};

export const JKUserManager = ({
  userId,
}: React.PropsWithChildren<JKUserManagerProps>) => {
  const {getConfig} = useConfig();
  const {user} = useAuthorize<any>();
  const find = useApi(
    userId ? BackendJkUserService.findAll : FrontendJkUserService.findAll
  );

  const del = useApi(
    userId ? BackendJkUserService.delete : FrontendJkUserService.delete
  );

  const $JKUserEdit = useMemo(() => {
    return withProps({
      userId,
    })(JKUserEdit);
  }, [userId]);

  const $JKUserBatchEdit = useMemo(() => {
    return withProps({
      userId,
    })(JKUserBatchUpdate);
  }, [userId]);

  const $DetailLink = useMemo(() => {
    return withProps((props: any) => ({
      children: '置頂記錄',
      state: {
        title: `${props.data.username}置頂記錄`,
      },
    }))(CommonManager.RowButton);
  }, []);

  const rowActions = useMemo(() => {
    const result = userId
      ? [$JKUserEdit, BackendJKUserNewOffDate]
      : [$JKUserEdit, $DetailLink, BackendJKUserNewOffDate];
    return result;
  }, [user, userId]);

  return (
    <VerifyEmail>
      {user.role === 'TRIAL_USER' && (
        <Alert
          message="請注意，試用會員無法刪除已建立的捷克會員資料"
          type="warning"
        />
      )}
      <CommonManager
        headerActions={[$JKUserEdit, $JKUserBatchEdit]}
        rowActions={rowActions}
        request={genRequest(find)}
        params={{
          userId,
          sortKey: 'errorCount',
          sortType: 'DESC',
        }}
        rowKey="id"
        rowSelection={{}}
        onDelete={
          user.role === 'TRIAL_USER'
            ? undefined
            : async row => {
                await del({id: row.id});
              }
        }
        onDisabledDelete={(row: any) => row.isDisabled === false}
        disabledDeleteTooltip="請先將帳號暫停執行後，才可進行刪除"
        columns={[
          {
            title: '狀態',
            key: 'status',
            render: (dom, row: any, idx, act) => {
              if (row.isDisabled) {
                return (
                  <CustomButton type="text" size="small">
                    暫停
                  </CustomButton>
                );
              }
              if (row.errorTotal >= getConfig('ERROR_BLACK_LIMIT')) {
                if (userId) {
                  return (
                    <Popconfirm
                      title={`提醒：已連續執行錯誤${row.errorTotal}次，是否要解除鎖定並重置錯誤次數？`}
                      onConfirm={async () => {
                        await BackendJkUserService.update({
                          id: row.id,
                          requestBody: {errorCount: 0, errorTotal: 0},
                        });
                        act?.reload();
                      }}
                    >
                      <CustomButton danger type="link" size="small">
                        鎖定
                      </CustomButton>
                    </Popconfirm>
                  );
                }
                return (
                  <Popconfirm
                    title={`提醒：已連續執行錯誤${row.errorTotal}次，已鎖定執行，請聯絡管理員進行解鎖`}
                    showCancel={false}
                  >
                    <CustomButton danger type="link" size="small">
                      鎖定
                    </CustomButton>
                  </Popconfirm>
                );
              }
              if (row.errorCount >= getConfig('ERROR_STOP_LIMIT')) {
                return (
                  <Popconfirm
                    overlayStyle={{maxWidth: 300}}
                    title={
                      <div>
                        提醒：已連續執行錯誤{row.errorCount}
                        次，暫時鎖定，是否要解鎖？
                        <br />
                        <Typography.Text type="danger">
                          (注意：若連續執行錯誤
                          {getConfig('ERROR_BLACK_LIMIT')}
                          次，將進行鎖定，需請管理員進行解鎖)
                        </Typography.Text>
                      </div>
                    }
                    onConfirm={async () => {
                      if (userId) {
                        await BackendJkUserService.update({
                          id: row.id,
                          requestBody: {
                            errorCount: 0,
                          },
                        });
                      } else {
                        await FrontendJkUserService.update({
                          id: row.id,
                          requestBody: {
                            errorCount: 0,
                          },
                        });
                      }

                      act?.reload();
                    }}
                  >
                    <CustomButton danger type="link" size="small">
                      暫停執行
                    </CustomButton>
                  </Popconfirm>
                );
              }
              return (
                <CustomButton
                  type="text"
                  size="small"
                  style={{pointerEvents: 'none'}}
                >
                  正常{row.isDisabled ? '(暫停中)' : ''}
                </CustomButton>
              );
            },
          },
          CommonTable.Utils.Sorter({
            title: '暱稱',
            dataIndex: 'nickname',
          }),
          CommonTable.Utils.Sorter({
            title: '帳號',
            dataIndex: 'username',
          }),
          CommonTable.Utils.Sorter({
            title: '密碼',
            dataIndex: 'password',
          }),
          CommonTable.Utils.Sorter({
            title: '文章連結',
            dataIndex: 'postUrl',
            render: dom => (
              <a href={dom as any} target="_blank">
                {dom}
              </a>
            ),
          }),
          CommonTable.Utils.Sorter({
            title: '執行時間',
            dataIndex: 'startTime',

            render: (dom, row) => [
              minute2time(row.startTime),
              '-',
              minute2time(row.endTime),
            ],
          }),
          CommonTable.Utils.Sorter({
            title: '執行星期',
            dataIndex: 'weeks',
            valueType: 'checkbox',
          }),
          CommonTable.Utils.Sorter({
            title: '已失敗次數',
            dataIndex: 'errorCount',
          }),
          CommonTable.Utils.Sorter({
            title: '總失敗次數',
            dataIndex: 'errorTotal',
          }),
          CommonTable.Utils.Sorter({
            title: '最後執行時間',
            dataIndex: 'lastRunAt',
            valueType: 'dateTime',
          }),
        ]}
      />
    </VerifyEmail>
  );
};
