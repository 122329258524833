//TODO: 方案詳細資料

import {ProFormDependency, ProFormDigit} from '@ant-design/pro-form';
import {FrontendUserPointCardService, PepayFormBody} from '@common/api';
import {Typography} from 'antd';
import {CustomButton} from 'components/Common/Button';
import {CommonManagerEdit} from 'components/Common/Manager/Edit';
import {useApi} from 'hooks/useApi';
import {useRef, useState} from 'react';
import {BackendPlanSelect} from '../Plan/Select';

export const BackendPointCardBuy = () => {
  const buy = useApi(FrontendUserPointCardService.buy);
  const [pepayForm, setPepayForm] = useState<PepayFormBody>();
  const formRef = useRef<HTMLFormElement>(null);
  const [plan, setPlan] = useState<any>({});
  return (
    <CommonManagerEdit
      title="我要儲值"
      trigger={<CustomButton>我要儲值</CustomButton>}
      submitter={pepayForm ? false : undefined}
      modalProps={{
        closable: pepayForm ? false : undefined,
        maskClosable: pepayForm ? false : undefined,
      }}
      onFinish={async values => {
        const {planId, count} = values;
        const res = await buy({
          requestBody: {
            planId,
            count,
          },
        });

        if (res.data) {
          setPepayForm(res.data);
        }

        setTimeout(() => {
          if (formRef.current) {
            formRef.current.submit();
          }
        }, 3000);

        return false;
      }}
    >
      {pepayForm ? (
        <>
          <Typography.Title>
            即將跳轉至付款畫面，請勿重新整理或是關閉網頁。
          </Typography.Title>
          <form
            ref={formRef}
            method="POST"
            action="https://api.55168957.com/paysel_amt.php"
          >
            {Object.keys(pepayForm).map(key => (
              <input type="hidden" value={(pepayForm as any)[key]} name={key} />
            ))}
          </form>
        </>
      ) : (
        <>
          <BackendPlanSelect onChangePlan={setPlan} />
          <ProFormDigit
            name="count"
            label="購買張數"
            min={1}
            initialValue={1}
          />
          <ProFormDependency name={['count', 'planId']}>
            {({count}) => (
              <ProFormDigit
                label="小計(元)"
                fieldProps={{
                  value: count * plan?.price || 0,
                }}
                readonly
              />
            )}
          </ProFormDependency>
        </>
      )}
    </CommonManagerEdit>
  );
};
